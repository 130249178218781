import restaurant from "./images/poiana.jpg";
import biserica from "./images/ciuflea.jpg";
import img_card from "./images/b.jpg";
import imgOmSuflet from "./images/r1.jpg";
import imgOmSufletmb from "./images/5m.jpg";
import imgheader from "./images/r.jpg";
import img2 from "./images/f.jpg";
import imgheadermiini from "./images/9.jpg";
import imgheadermiinimb from "./images/9m.jpg";
import imgconfirmare from "./images/11.jpg";
import logo from "./images/logo.png";


const data = {
    introData: [{
        familia: "I & C",
        logo: logo,
        mire: "Ion",
        mireasa: "Cristina",
        data: "11 August 2024",
        data_confirmare: "25 iunie 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "cristinacaragia1992@gmail.com", 
       tel: "+39 389 682 8128",
       phone: "tel:+393896828128",
       viber: "viber://chat?number=%2B393896828128",
       whatsapp: "https://wa.me/+393896828128",
       messenger: "https://www.messenger.com/t/xxx",
       tel1: "+373 787 78 418",
       phone1: "tel:+37378778418",
       viber1: "viber://chat?number=%2B37378778418",
       whatsapp1: "https://wa.me/+37378778418",
       messenger1: "https://www.messenger.com/t/carolina.talmazan.1",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Cununia Religioasă",
            localul: "",
            name: "Mănăstirea Ciuflea",
            data: "11 august 2024, duminică, ora 13:00",
            adress: "or. Chişinău, str. Ciuflea, 12",
            harta: "https://maps.app.goo.gl/1riz1M3sBUQeEycZ8",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentelor",
            localul: "Complexul Turistic",
            name: "Poiana Bradului",
            data: "11 august 2024, duminică, ora 16:00",
            adress: "Ciocana, Chişinău, Moldova",
            harta: "https://goo.gl/maps/PKVWn6hNjxnui6qn8",
            iframe: "" 
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Salutare!",
            title2: "Mă numesc, Damir!",
            mami: "Cristina",
            tati: "Ion & Cristina",
            message: "Împreună cu mami și tati, ",
            message1: "avem un anunț important de făcut! ",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Cununia Religioasă și Cumetria lui DAMIR!",
            message: "Pentru că cele mai frumoase momente se sărbătoresc alături de cei dragi, vrem să fim înconjurați de cei mai apropiați și scumpi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celor mai semnificative evenimente din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "CU BINECUVÂNTAREA ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Ghenadie și Natalia Sîma",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă ați fi alături de noi într-o zi atât de specială!",
            message: "",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;